import { BindPhoneNumberRequest, LoginResponse, SmsVerificationRequest } from "@/models/login"
import { apiClient } from "./http"
import { WeixinProfilerResponse } from "@/models/weixin"
import { i18n } from '@/i18n'
const $t = i18n.global.t
import { useToast } from '@/utils/useToast'
import Toast from '@/components/common/popup/toast.vue'
/**
 * @description 登入
 * @param {string} code
 */
export const Login = async (code: string): Promise<LoginResponse> => {
    const endpoint = '/user/login/weixin'
    const data = {
        code
    }
    try {
        const res = await apiClient({
            url: endpoint,
            method: 'get',
            params: data
        })
        return await Promise.resolve(res)
    } catch (error) {
        return await Promise.reject(error)
    }
}

/**
 * @description 取用戶個人檔案
 * @returns { UserProfileResponse }
 */
export const getUserProfile = async (): Promise<WeixinProfilerResponse> => {
    const endpoint = '/user/profile'
    try {
        const res = await apiClient({
            url: endpoint,
            method: 'get'
        })
        return await Promise.resolve(res)
    } catch (error) {
        return await Promise.reject(error)
    }
}



/**
 * @description 绑定手机号 用户通过验证码验证后的成功反馈接口
 * @param { BindPhoneNumberRequest } data
 * @returns
 */
export const bindPhoneNumber = async (data: BindPhoneNumberRequest): Promise<any> => {
    const endpoint = '/user/login/sms'
    try {
        const res = await apiClient({
            url: endpoint,
            method: 'post',
            data
        })
        return await Promise.resolve(res)
    } catch (error: any) {
        const { data } = error
        const { code, message } = data
        if (code == 'MSS_ERR_SMS_DUPLICATED') {
            useToast({
                component: Toast,
                props: {
                    icon: 'error',
                    title: $t('bindingPhonePage.toast1.title'),
                    content: $t('bindingPhonePage.toast1.content'),
                },
            })
        } else {
            useToast({
                component: Toast,
                props: {
                    icon: 'error',
                    title: code,
                    content: message,
                },
            })
        }
        return await Promise.reject(error)
    }
}

/**
 * @description 短信验证码验证并绑定手机号
 * @param { SmsVerificationRequest } data
 * @returns 
 */
export const smsVerification = async (data: SmsVerificationRequest): Promise<any> => {
    const endpoint = '/user/login/sms-verify'
    try {
        const res = await apiClient({
            url: endpoint,
            method: 'post',
            data
        })
        return await Promise.resolve(res)
    } catch (error: any) {
        const { data } = error
        const { code, message } = data
        if (code == 'MSS_ERR_SMS_CODE_NOT_MATCH') {
            useToast({
                component: Toast,
                props: {
                    icon: 'error',
                    title: $t('bindingPhonePage.toast2.title'),
                    content: $t('bindingPhonePage.toast2.content'),
                },
            })
        } else if (code == 'MSS_ERR_FAILED') {
            useToast({
                component: Toast,
                props: {
                    icon: 'error',
                    title: code,
                    content: message,
                },
            })
        } else {
            useToast({
                component: Toast,
                props: {
                    icon: 'error',
                    title: code,
                    content: message,
                },
            })
        }
        return await Promise.reject(error)
    }
}

/**
 * @description 账号密码登入
 * @param username 
 * @param password 
 * @returns 
 */
export const userAccountLogin = async (username: string, password: string) => {
    const endpoint = '/user/login/authn'
    try {
        const res = await apiClient({
            url: endpoint,
            method: 'post',
            data: {
                username,
                password,
                timestamp: new Date().getTime()
            }
        })
        return await Promise.resolve(res)
    } catch (error) {
        return await Promise.reject(error)
    }
}