<script setup lang="ts">
import { onMounted } from 'vue'
import { useGeolocation, lazyAMapApiLoaderInstance } from '@vuemap/vue-amap'
import { useFlightStore } from './store/flight'
import { useUserStore } from './store/user'
import { router } from './router'
import { getUrlParams } from './utils/util'
import { PageName, StorageKey } from './models/enums'
import LaunchPopup from '@/components/common/popup/launch-popup.vue'
import storage from './utils/storage'
import { IS_DEV } from '@/config/api'
import { userAccountLogin } from './api/authn'
import { useRoute } from 'vue-router'
import { usePopup } from './utils/usePopup'
const flightStore = useFlightStore()
const userStore = useUserStore()
const route = useRoute()
const pageUrl = window.location.href

const checkForValidLogin = () => {
  if (userStore.checkTokenIsExpired()) {
    console.info(`token expired`)
    userStore.logout()
    const code = new URL(window.location.href).searchParams.get('code')
    const state = new URL(window.location.href).searchParams.get('state')
    console.info(`get wechat code:`, code)
    if (code) {
      userStore.userInfo.wechatCode = code
      // storage.setItem(StorageKey.WX_CODE_URL, window.location.href)
      // test code
      // window.location.reload()
    }
    if (state) {
      console.log('hotspot from qrcode:', state)
    }
    router.replace({ name: PageName.HOME })
  } else {
    console.info(`The token exists and has not expired`)
    userStore.setUserTokens()
    if (userStore.userInfo.userTokens.phone_number) {
      userStore.storageUserTokens()
      // router.replace({ name: PageName.HOME })
    } else {
      router.replace({ name: PageName.BINDING_PHONE })
    }
  }
}

onMounted(async () => {
  if (IS_DEV && userStore.checkTokenIsExpired()) {
    console.log('模拟登录', `是否开发环境：${IS_DEV}`)
    const res = await userAccountLogin('sunny', 'sunny6688')
    const { access_token, expires_at } = res
    storage.setItem(StorageKey.USER_TOKENS, {
      openid: 'oBMqB6YYyWMwDUkHwXv_wF4E8xUA',
      access_token,
      expires_at,
      phone_number: '13888888888',
    })
  }
  if (pageUrl.indexOf('/memory/hotpot-detail/video') > -1) {
    return
  }
  checkForValidLogin()
  // 用于jssdk url签名参数
  storage.setItem(StorageKey.WX_CODE_URL, window.location.href)
})
</script>

<template>
  <div class="app-page">
    <RouterView></RouterView>
  </div>
</template>

<style scoped lang="scss">
.app-page {
  height: 100vh;
}

:deep(.amap-logo) {
  display: none !important;
}

:deep(.amap-copyright) {
  display: none !important;
}
</style>
